@font-face {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold"), url("../src/fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular"), url("../src/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium"), url("../src/fonts/Gilroy-Medium.ttf") format("truetype");
}

.Header {
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 25px;
}

.Footer {
  background-color: grey;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;
  font-family: 'Gilroy-Medium';
  font-size: 18px;
  color:white;
}

.App-logo {
  width: 100px;
}

.text1 {
  width: 25%;
  background-color: #B4BEF0;
  font-family: 'Gilroy-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  text-align: left;
}

.text2 {
  width: 25%;
  background-color: #FFF7B2;
  font-family: 'Gilroy-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  text-align: left;
}

.text3 {
  width: 25%;
  background-color: #A3E8DD;
  font-family: 'Gilroy-Bold';
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  text-align: left;
}

.image {
  position: relative;
  width: 25%;
}

.image__img {
  display: block;
  width:100%;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  font-family: 'Gilroy-Medium';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}


.image__overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  font-size: 20px;
  font-weight: normal;
}

.Row {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 960px){
  .Row {
    display: flex;
    flex-direction: column;
  }
  .text1 {
    width:100%;
  }
  .text2 {
    width:100%;
  }
  .text3 {
    width:100%;
  }
  .image {
    width:100%;
  }
  .image__img{
    width:100%;
  }
  .Footer{
    font-size:16px;
  }
}
